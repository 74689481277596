import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApiClient } from 'src/client/helpers/ApiClient';
import { apiUrls, routerPaths } from 'src/client/helpers/constants';
import { useAppData } from 'src/client/helpers/useAppData';
import { Biller } from 'src/shared/constants/billers';
import { GetPricesResponse } from 'src/shared/types/Prices';
import { useQueryParams } from './PreJoinPage';
import { useAffiliateData } from '../../helpers/useAffiliateData';

export const usePreJoinPagePrices = (
  setPaymentOption: React.Dispatch<React.SetStateAction<AltPayment | undefined>>,
) => {
  const { user } = useAppData();
  const purchaseMatch = !!useRouteMatch({ exact: true, path: routerPaths.purchase.site });
  const nonNudePurchaseMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.purchase.site });
  const history = useHistory();
  const { addToast } = useToasts();
  const { affiliateID, promoCode, giftCard, purchaseResponse, biller, ipAddress } = useQueryParams();
  const [data, setData] = useState<GetPricesResponse>();
  const { isMAMCookie, isPACookie } = useAffiliateData();

  const params = useMemo(
    () => ({
      affiliateID,
      exclude: purchaseResponse === 'declined' && biller ? biller : undefined,
      giftCard,
      ipAddress,
      promoCode,
    }),
    [affiliateID, biller, giftCard, ipAddress, promoCode, purchaseResponse],
  );

  useEffect(() => {
    if (purchaseMatch || nonNudePurchaseMatch) {
      return;
    }

    (async () => {
      const api = new ApiClient();
      try {
        const { body } = (await api.get(apiUrls.get.prices, { params })) as { body: GetPricesResponse };
        const { paymentPrices, previousBillerID, activeSub } = body;

        if (paymentPrices.length === 0 && user?.isUser && ![Biller.GoCoin, Biller.BitPay].includes(previousBillerID)) {
          history.push(routerPaths.purchase.upsales);
          return;
        }

        if (paymentPrices.length === 0) {
          throw new Error('PRICES_ERROR');
        }

        const altPayments = Object.values(body.altPayments ?? {}).flatMap((a) => [...a] as AltPayment[]);

        const findPrimaryPaymentOption = (billerID?: number) => {
          if (!billerID) {
            return undefined;
          }

          // we are going to try to get the primary payment button first.
          const paymentPrice = paymentPrices.find(
            (payment) =>
              payment.billerID === billerID &&
              (payment.paymentType === 'Credit Card' || payment.paymentType === 'PayPal'),
          );

          // this will find the alternative payment option
          let primaryPayment = altPayments.find((payment) => payment.billerID === billerID);

          // if we have the primary payment button pricing, use that over the alternative option.
          if (paymentPrice) {
            primaryPayment = { name: paymentPrice.paymentType, billerID: paymentPrice.billerID };
          }

          return primaryPayment;
        };

        let paymentOption =
          findPrimaryPaymentOption(activeSub?.billerID) ||
          findPrimaryPaymentOption(previousBillerID) ||
          // at this point we should always have a payment option since the paymentPrices array should not be empty
          findPrimaryPaymentOption(paymentPrices[0].billerID);

        if (isPACookie) {
          paymentOption = findPrimaryPaymentOption(Biller.CCBill) || paymentOption;
        }

        if (isMAMCookie) {
          paymentOption = findPrimaryPaymentOption(Biller.RocketGate) || paymentOption;
        }

        const subscriptionPlans = paymentPrices.find((r) => r.billerID === paymentOption?.billerID)
          ?.subscriptionPlans[0];

        if (!subscriptionPlans) {
          history.push(routerPaths.purchase.upsales);
        }

        setPaymentOption(paymentOption);
        setData(body);
      } catch (error) {
        addToast('Unable to get subscription prices', { appearance: 'error', autoDismiss: false });
      }
    })();

    window.ata?.track({ name: 1 });
  }, [addToast, history, isMAMCookie, isPACookie, nonNudePurchaseMatch, params, purchaseMatch, setPaymentOption, user]);

  return data;
};
